<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{ $t('elearning_venue.venue_reservation') }}
              <slot v-if="$route.query.id">
                {{ $t('elearning_config.update') }}
              </slot>
              <slot v-else>
                {{ $t('globalTrans.entry') }}
              </slot>
            </h4>
          </template>
          <template v-slot:headerAction>
            <router-link to="venue-reservation" :class="'btn btn-success text-light'">{{ $t('elearning_venue.venue_reservation') }} {{ $t('globalTrans.list') }}</router-link>
          </template>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <b-alert v-if="!booking" variant="danger" show dismissible fade>{{ $t('elearning_venue.this_vanue_is_reserved_in_this_date_and_time') }} </b-alert>
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                      <b-row>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.fiscal_year_id"
                                  :options="fiscalYearList"
                                  id="fiscal_year_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">
                          <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.org_id"
                                  :options="orgList"
                                  id="org_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="office_type_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.office_type')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.office_type_id"
                                  :options="officeTypeList"
                                  id="office_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="office_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.office')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.office_id"
                                  :options="officeList"
                                  id="office_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6" v-if="$store.state.Auth.authUser.org_id !== 9 && formData.org_id !== 9">
                          <ValidationProvider name="Venue"  vid="venue_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="venue_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.type_of_applicant')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.gov_private"
                                :options="govOrPvt"
                                id="gov_private"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <!-- <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option> -->
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6" v-if="$store.state.Auth.authUser.org_id == 9 || formData.org_id == 9">
                          <ValidationProvider name="Venue"  vid="venue_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="venue_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.type_of_applicant')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.gov_private"
                                :options="binaUserType"
                                id="gov_private"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <!-- <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option> -->
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Venue"  vid="venue_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="venue_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_tpm.vanue')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.venue_id"
                                :options="venus"
                                id="venue_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Total Capacity"  vid="total_capacity" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="total_capacity"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_venue.total_capacity')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="total_capacity"
                                v-model="formData.total_capacity"
                                :placeholder="$t('elearning_venue.total_capacity')"
                                :state="errors[0] ? false : (valid ? true : null)"
                                readonly
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Rent Amount"  vid="rent_amount" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="rent_amount"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_venue.rent_amount')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="rent_amount"
                                v-model="formData.rent_amount"
                                :placeholder="$t('elearning_venue.rent_amount')"
                                :state="errors[0] ? false : (valid ? true : null)"
                                readonly
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Address"  vid="address" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="address"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.address')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-textarea
                                id="address"
                                v-model="formData.address"
                                :placeholder="$t('globalTrans.address')"
                                :state="errors[0] ? false : (valid ? true : null)"
                                v-if="$i18n.locale === 'en'"
                                readonly
                              ></b-form-textarea>
                              <b-form-textarea
                                id="address"
                                v-model="formData.address_bn"
                                :placeholder="$t('globalTrans.address')"
                                :state="errors[0] ? false : (valid ? true : null)"
                                v-else
                                readonly
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="6" sm="6">
                        <ValidationProvider name="Start Date" vid="start_date" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="start_date"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('globalTrans.start_date')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                class="fromDate"
                                v-model="formData.start_date"
                                :placeholder="$t('globalTrans.select_date')"
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="End Date" vid="end_date" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="end_date"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.end_date')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  class="fromDate"
                                  v-model="formData.end_date"
                                  :placeholder="$t('globalTrans.select_date')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Start Time" vid="start_time" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="start_time"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.start_time')}}
                              </template>
                              <b-form-input
                                type= "time"
                                v-model="formData.start_time"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="End Time" vid="end_time" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="end_time"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.end_time')}}
                              </template>
                              <b-form-input
                                type= "time"
                                v-model="formData.end_time"
                                :placeholder="$t('globalTrans.select_date')"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Reason (En)"  vid="reason" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="reason"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.reason_en')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-textarea
                                  rows="2"
                                  id="reason"
                                  v-model="formData.reason"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Reason (Bn)"  vid="reason_bn">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="reason_bn"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.reason_bn')}}
                              </template>
                              <b-form-textarea
                                  rows="2"
                                  id="reason_bn"
                                  v-model="formData.reason_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <fieldset class="p-2 w-100">
                        <legend class="px-2 w-50 shadow-sm">{{$t('elearning_venue.reservation_info')}}</legend>
                        <div class="col-md-12">
                          <b-row>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Name (En)"  vid="name" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="name"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('globalTrans.name_en')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                    id="name"
                                    v-model="formData.name"
                                    :placeholder="$t('globalTrans.name_en')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Name BN"  vid="name_bn" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="name_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('globalTrans.name_bn')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                    id="name_bn"
                                    v-model="formData.name_bn"
                                    :placeholder="$t('globalTrans.name_bn')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Email"  vid="email" rules="required|email|min:3">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="email"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('globalTrans.email')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                    id="email"
                                    v-model="formData.email"
                                    :placeholder="$t('globalTrans.email')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Mobile"  vid="mobile" rules="required|min:11|max:11">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="mobile"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('globalTrans.mobile')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                  type="number"
                                    id="mobile"
                                    v-model="formData.mobile"
                                    :placeholder="$t('globalTrans.mobile')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Organization"  vid="reservation_org_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="reservation_org_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.reservation_org_id"
                                      :options="orgGuestList"
                                      id="reservation_address"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="formData.reservation_org_id == 999">
                                <ValidationProvider name="Reservation Organization Name" vid='reservation_org_name' rules="">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="reservation_org_name"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{ $t('elearning_venue.reservation_org_name')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="reservation_org_name"
                                        v-model="formData.reservation_org_name"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                                <ValidationProvider name="Total Participent"  vid="total_participent" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="total_participent"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('elearning_venue.total_participent')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        type="number"
                                        id="total_participent"
                                        v-model="formData.total_participent"
                                        :placeholder="$t('elearning_venue.total_participent')"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Address (En)"  vid="reservation_address">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="reservation_address"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('globalTrans.address_en')}}
                                  </template>
                                  <b-form-textarea
                                    id="reservation_address"
                                    v-model="formData.reservation_address"
                                    :placeholder="$t('globalTrans.address_en')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-textarea>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Address (Bn)"  vid="reservation_address_bn">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="reservation_address_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('globalTrans.address_bn')}}
                                  </template>
                                  <b-form-textarea
                                    id="reservation_address_bn"
                                    v-model="formData.reservation_address_bn"
                                    :placeholder="$t('globalTrans.address_bn')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-textarea>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                          </b-row>
                        </div>
                      </fieldset>
                      <div class="row">
                          <div class="col-sm-3"></div>
                          <div class="col text-right">
                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                            &nbsp;
                            <router-link to="venue-reservation" class="mr-1 btn btn-danger">{{ $t('globalTrans.cancel') }}</router-link>
                          </div>
                        </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<style>
.alert .close {
  color: red !important;
}
</style>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { venueReservationStore, venueReservationUpdate, vanueManagementShow, venueReservationShow } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.formData = Object.assign({}, this.formData, {
      org_id: this.$store.state.dataFilters.orgId,
      fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
      // office_type_id: this.$store.state.dataFilters.officeTypeId,
      // office_id: this.$store.state.dataFilters.officeId
    })
    if (this.$route.query.id) {
      this.getFormData()
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        circular_memo_no: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        venue_id: 0,
        total_capacity: '',
        total_participent: '',
        rent_amount: '',
        address: '',
        contact_person_no: '',
        start_date: '',
        end_date: '',
        reservation_org_id: 0,
        reservation_org_name: '',
        gov_private: 1,
        status: 1
      },
      booking: true,
      officeTypeList: [],
      officeList: [],
      venus: [],
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl
    }
  },
  computed: {
    govOrPvt: function () {
      const govOrPvt = [
        {
          value: 1,
          text: 'govt',
          text_en: 'govt',
          text_bn: 'সরকারী'
        },
        {
          value: 2,
          text: 'private',
          text_en: 'private',
          text_bn: 'ব্যক্তিগত'
        }
      ]
      const tmpList = govOrPvt.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn, text_en: obj.text_en, text_bn: obj.text_bn }
        } else {
            return { value: obj.value, text: obj.text_en, text_en: obj.text_en, text_bn: obj.text_bn }
        }
      })
      return tmpList
    },
    binaUserType: function () {
      const userType = [
        {
          value: 1,
          text: 'NARS Institute',
          text_en: 'NARS Institute',
          text_bn: 'এনএআরএস ভূক্ত প্রতিষ্ঠান'
        },
        {
          value: 2,
          text: 'Govt. Organization/University',
          text_en: 'Govt. Organization/University',
          text_bn: 'সরকারি সংস্থা/সরকারি বিশ্ববিদ্যালয়'
        },
        {
          value: 3,
          text: 'Private University/Organization',
          text_en: 'Private University/Organization',
          text_bn: 'বেসরকারি বিশ্ববিদ্যালয়/সংস্থা'
        },
        {
          value: 4,
          text: 'Institute Scientist/Officer/Employee/Labourer Son/daughter except other use',
          text_en: 'Institute Scientist/Officer/Employee/Labourer Son/daughter except other use',
          text_bn: 'ইনস্টিটিউটের বিজ্ঞানী/কর্মকর্তা/কর্মচারী/শ্রমিক পুত্র/কন্যা ছাড়া অন্যান্য ব্যবহার'
        },
        {
          value: 5,
          text: 'Only Agricultural Organization',
          text_en: 'Only Agricultural Organization',
          text_bn: 'শুধুমাত্র কৃষি বিষয়ক প্রতিষ্ঠান'
        }
      ]
      const tmpList = userType.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn, text_en: obj.text_en, text_bn: obj.text_bn }
        } else {
            return { value: obj.value, text: obj.text_en, text_en: obj.text_en, text_bn: obj.text_bn }
        }
      })
      return tmpList
    },
    orgList: function () {
        if (this.$store.state.Auth.authUser.org_id === 12) {
            return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0 && item.value === 12)
        } else {
            return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
        }
    },
    // officeTypeList: function () {
    //     return this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
    // },
    // officeList: function () {
    //     return this.$store.state.commonObj.officeList.filter(item => item.status === 0)
    // },
    orgGuestList: function () {
        if (this.$store.state.Auth.authUser.org_id === 12) {
            const orgData = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0 && item.value === 12)
            orgData.push(
                {
                    value: 999,
                    text: 'Other',
                    text_en: 'Other',
                    text_bn: 'অন্যান্য'
                }
            )
            return orgData
        } else {
            const orgData = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
            orgData.push(
                {
                    value: 999,
                    text: 'Other',
                    text_en: 'Other',
                    text_bn: 'অন্যান্য'
                }
            )
            return orgData
        }
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    }
  },
  watch: {
    'formData.venue_id': function (newValue) {
      this.getVenueInfo(newValue)
    },
    'formData.org_id': function (newValue) {
      this.officeTypeList = this.getOfficeTypeList(newValue)
      // this.venus = this.getVenusList(newValue)
    },
    'formData.office_id': function (newValue) {
      this.venus = this.getOfficeVenusList(newValue)
    },
    'formData.office_type_id': function (newValue) {
      this.officeList = this.getOfficeList(newValue)
    },
    'formData.gov_private': function (newValue) {
       this.getVenueInfo(this.formData.venue_id)
    }
  },
  methods: {
    async getFormData () {
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, venueReservationShow + '/' + this.$route.query.id)
      if (!result.success) {
        this.formData = []
      } else {
        this.formData = result.data
      }
    },
    getVenusList (orgId) {
      let venueList = this.$store.state.TrainingElearning.commonObj.venus.filter(item => item.status === 1)
      if (orgId) {
        venueList = venueList.filter(item => item.org_id === orgId)
        if (this.$store.state.dataFilters.officeId) {
          venueList = venueList.filter(item => parseInt(item.office_id) === parseInt(this.$store.state.dataFilters.officeId))
        }
      }
      return venueList
    },
    getOfficeVenusList (officeId) {
      const venueList = this.$store.state.TrainingElearning.commonObj.venus.filter(item => item.status === 1)
      if (officeId) {
        return venueList.filter(item => parseInt(item.office_id) === parseInt(officeId))
      }
    },
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return OfficeTypeList.filter(item => item.org_id === orgId)
      }
      return OfficeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    async getVenueInfo (venueId) {
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, vanueManagementShow + '/' + venueId)
      if (!result.success) {
        this.formData.total_capacity = 0
        this.formData.rent_amount = 0
        this.formData.address = 0
        this.formData.address_bn = 0
        this.formData.contact_person_no = 0
      } else {
        const trData = result.data
        this.formData.total_capacity = trData.total_capacity
         if (this.formData.gov_private === 1) {
          this.formData.rent_amount = trData.rent_amount
        } else if (this.formData.gov_private === 2) {
          this.formData.rent_amount = trData.rent_amount_pvt
        } else if (this.formData.gov_private === 3) {
          this.formData.rent_amount = trData.private_org_uni_rent_amount
        } else if (this.formData.gov_private === 4) {
          this.formData.rent_amount = trData.ins_sci_officer_labour_rent_amount
        } else {
          this.formData.rent_amount = trData.only_agri_org
        }
        this.formData.address = trData.address
        this.formData.address_bn = trData.address_bn
        this.formData.contact_person_no = trData.contact_person_no
      }
    },
    async createData () {
      this.loading = true
      let result = null
      if (this.formData.id) {
        result = await RestApi.putData(trainingElearningServiceBaseUrl, `${venueReservationUpdate}/${this.formData.id}`, this.formData)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, venueReservationStore, this.formData)
      }
      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('elearning_config.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        if (this.$route.query.other === 0) {
          this.$router.push('/training-e-learning-service/venue/review-venue-reservation')
        } else {
          this.$router.push('/training-e-learning-service/venue/venue-reservation')
        }
      } else {
        if (!result.booking) {
          this.booking = false
          this.$toast.error({
            title: 'Error',
            message: this.$t('elearning_venue.this_vanue_is_reserved_in_this_date_and_time'),
            color: '#D6E09B'
          })
        } else {
          this.$refs.form.setErrors(result.errors)
        }
      }
    }
  }
}
</script>
